<template>
	<div :key="key">
		<div class="booking-tabs">
			<div class="booking-toolbar ">
				<div class="md-booking-toolbar" style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; align-items:  center; align-content: center; height: 50px; background-color: #fff" >
					<div class="" style="width: 40px; text-align: center" >
						<router-link to="/calendario" style="padding: 10px 10px 10px 2px" >
							<md-icon>today</md-icon>
						</router-link>
					</div>
					<div class="" style="flex-grow:  1; ">
						<md-tabs md-swipeable md-sync-route>
							<md-tab  id="ingresadas_hoy" :md-label="lang.ingresadas_hoy" :to="{name:'nuevas_hoy'}"></md-tab>
							<md-tab id="eliminadas_hoy"	:md-label="lang.eliminadas_hoy" :to="{name:'canceladas_hoy'}"></md-tab>
						</md-tabs>
					</div>

				</div>
			</div>
			<md-content class="tab-content">
				<router-view/>
			</md-content>
		</div>
	</div>
</template>
<script>
	import {request, toFormData} from "../../helpers";
	import Header from "../Header";
	export default {
		components: {Header},
		props   : ['id'],
		name    : 'md-calendario-upadates',
		computed: {
			lang() {return this.$store.state.lang},
		},
		methods:{

		},
		beforeMount(){
			this.$store.commit('setTitle', 'Eventos');
		},
		mounted() {
			this.$store.state.loading = false;
		},

		data() {
			return {
				key :0
			}
		}
	}
</script>
